import React, { useEffect, useCallback } from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'

const OurHistoryPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Our',
    title2: 'History',
    breadcrumbs: ['Who We Are'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  const createScript = useCallback(() => {
    const script = document.createElement('script')
    script.src = '//my.visme.co/visme-embed.js'
    script.async = true
    script.type = 'text/javascript'
    document.querySelector('#historyTimeline').appendChild(script)
  }, [])

  useEffect(() => {
    createScript()
    return () => {
      if (document.querySelector('#historyTimeline')) {
        document.querySelector('#historyTimeline').removeChild('script')
      }
    }
  }, [createScript])

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Time Line */}
        <SectionContainer
          id="historyTimeline"
          color="gray"
          type="contained"
          classes={['sectionTopMargin']}
        >
          <script src="//my.visme.co/visme-embed.js"></script>
          <div
            className="visme_d"
            data-title="30 Years Digital Timeline"
            data-url="y4m1wvpx-30-years-digital-timeline"
            data-w="800"
            data-h="9924"
            data-domain="my"
          ></div>
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default OurHistoryPage
